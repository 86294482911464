/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

import ReactDOM from 'react-dom/client';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Toaster } from 'react-hot-toast';

import './assets/style.css';

const Login = lazy(()=>import('./pages/login/index'));

const App = lazy(()=>import('./pages/app/index'));

const Home = lazy(()=>import('./pages/app/home/index'));
const Excluded = lazy(()=>import('./pages/app/excluded/index'));

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
  <>
    <Toaster toastOptions={{
      style: {
        backgroundColor: "var(--toast-background-color)",
        color: "var(--secondary-text-color)",
        borderRadius: '5px'
      }
    }}/>
    <Router>
      <Suspense fallback={
        <div className="w-full flex items-center justify-center" style={{ minHeight: '100vh' }}>
          <span className="loader black-loader"></span>
        </div>
      }>
        <Routes>
          <Route exact path="" element={<Login/>} />
          <Route path='app' element={<App/>}>
            <Route path='' element={<Home/>}/>
            <Route path='excluded' element={<Excluded/>}/>
          </Route>
        </Routes>
      </Suspense>
    </Router>
  </>
);
